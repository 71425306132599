import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {

  componentDidMount() {
    setInterval(function(){
      document.getElementById("toml").style.visibility=  "visible";
    },3000);
  }

  render() {



    return (
      <div style={{margin: "auto", width: 800, textAlign: "center", paddingTop: 60}}>
        <img src="image/baby.gif" style={{position: "fixed", top: 0, right: 0}}/>
        <img src="image/baby.gif" style={{position: "fixed", top: 0, left: 0}}/>
        <img src="image/baby.gif" style={{position: "fixed", bottom: 0, left: 0}}/>
        <img src="image/baby.gif" style={{position: "fixed", bottom: 0, right: 0}}/>

        <div className="Header">
        <img width="300" src="image/welcome.gif" />
          <h1 style={{fontSize: 60, color: "yellow", margin: 0}}>to</h1>
        <marquee style={{textDirection: "center"}} direction="right"><h1 className="rainbow" style={{fontSize: 100, margin: 0}}>Toml'z Site</h1> <img width={100} src="image/link.gif" /><img width={100} src="image/link.gif" /><img width={100} src="image/link.gif" /><img width={100} src="image/link.gif" /><img width={100} src="image/link.gif" /></marquee>

          <img id="toml" src="image/toml.gif" height={200} />           <img  id="toml" src="image/toml.gif" height={200} />
          <img  id="toml" src="image/toml.gif" height={200} />
          <img  id="toml" src="image/toml.gif" height={200} />


        <div>
          <span className="bevelBox"><a href="https://github.com/TomLingham">Ma Codez</a></span> <span className="bevelBox"><a href="https://twitter.com/tom_lingham">Ma Tweetz</a></span> <span className="bevelBox"><a href="https://www.mrsupplement.com.au/">Ma Body</a></span> <span className="bevelBox"><a href="http://users3.smartgb.com/g/g.php?a=s&i=g36-29331-c9">Ma Guestbook</a></span>
        </div>

        </div>
        <img width="300" src="image/welcome2.gif" />


        <p className="gradient" style={{color: "yellow", fontSize: 20, padding: 15}}>Created using ma favourite toolz:</p>
        <table style={{width: '100%', borderStyle: "ridge"}}>
          <tr>
            <td>
              <img src="image/flow.png" width={200} />
            </td>
            <td>
              <img src="image/make.png" width={200} />
            </td>
            <td>
              <img className="spinning-pic" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Rust_programming_language_black_logo.svg/1200px-Rust_programming_language_black_logo.svg.png" width={100} />

            </td>
          </tr>
        </table>



        <embed loop="true" autostart="true" style={{position: "absolute", bottom: 0}} height="0px" width="0px" src="song.mp3" />

        <br />        <br />
        <br />



        <p style={{color: "white", fontSize: 24, padding: 15, fontWeight: 800,  textShadow: "2px 2px 5px red"
        }}>Ma Hitz:</p>

        <a href="http://www.cutercounter.com/" target="_blank"><img width="200" src="http://www.cutercounter.com/hit.php?id=gvncxoc&nd=6&style=12" border="0" alt="visitor counter" /></a>

        <div><img src="image/ns_logo.gif" /><img src="image/ie_logo.gif" /><img src="image/noframes.gif" /><img src="image/notepad.gif" /></div>
      
      </div>
    );
  }
}

export default App;
